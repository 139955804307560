<template>
    <div>
        <Breadcrumb></Breadcrumb>
        <div class="header">
            <el-row>
                <div class="form">
                    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                        <el-form-item label="用户名">
                            <el-input v-model="searchForm.username" placeholder="用户名" size="small"></el-input>
                        </el-form-item>
                        <el-form-item label="账号状态">
                            <el-select v-model="searchForm.status" clearable placeholder="账号状态" size="small">
                                <el-option label="正常" value="1"></el-option>
                                <el-option label="注销" value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" icon="el-icon-search" @click="getUserList">查询</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-row>
            <el-row>
                <el-col :span="22">
                    <el-button type="danger" size="small" @click="delUser()">批量删除</el-button>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" size="small" @click="showForm('add')">
                        <i class="el-icon-circle-plus-outline"></i>
                        添加
                    </el-button>
                </el-col>
            </el-row>
        </div>
        <div class="content">
            <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange" :default-sort="{ prop: 'id', order: 'ascending' }">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="username" label="用户名"></el-table-column>
                <el-table-column prop="role_id" label="角色" :formatter="roleIdFormatter"></el-table-column>
                <el-table-column prop="sex" label="性别"></el-table-column>
                <el-table-column prop="phone" label="手机"></el-table-column>
                <el-table-column prop="email" label="邮箱"></el-table-column>
                <el-table-column prop="status" label="状态" :formatter="statusFormatter"></el-table-column>
                <el-table-column prop="last_login_time" label="上次登录时间" sortable></el-table-column>
                <el-table-column label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="showForm('edit', scope.row.id)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="delUser(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <Pagination :total="total" :perPage="perPage" :currentPage="currentPage" @currentPageChange="currentPageChange"></Pagination>
            </div>
        </div>

        <AddForm v-if="addFormDialog" :addFormDialog="addFormDialog" @closeForm="closeForm" @getUserList="getUserList"></AddForm>
        <EditForm v-if="editFormDialog" :editFormDialog="editFormDialog" :editFormId="editFormId" @closeForm="closeForm" @getUserList="getUserList"></EditForm>
    </div>
</template>

<script>
import { getUserList, delUser } from '@/utils/servers/adminUser.js';
import { getCheckRole } from '@/utils/servers/adminRole.js';
import Pagination from '@/components/Pagination/Pagination.vue';
import AddForm from './add.vue';
import EditForm from './edit.vue';
export default {
    components: {
        Pagination,
        AddForm,
        EditForm
    },
    computed: {
        roleIdFormatter() {
            return (row, column, value) => {
                let arr = this.checkRole.find(val => val.id === value);
                if (arr) {
                    return arr.name;
                } else {
                    return value;
                }
            };
        },
        statusFormatter() {
            return (row, column, value) => {
                let arr = [
                    { key: 1, name: '正常' },
                    { key: 2, name: '注销' }
                ];
                let item = arr.find(item => item.key === value);
                if (item) {
                    return item.name;
                } else {
                    return value;
                }
            };
        }
    },
    data() {
        return {
            searchForm: {
                username: '',
                status: ''
            },
            list: [],
            total: 0,
            perPage: 0,
            currentPage: 0,
            checkIds: [],
            checkRole: [],
            addFormDialog: false,
            editFormDialog: false,
            editFormId: 0
        };
    },
    created() {
        this.getCheckRole();
        this.getUserList();
    },
    methods: {
        handleSelectionChange(val) {
            this.checkIds = val ? val.map(item => item.id) : [];
        },
        currentPageChange(val) {
            this.currentPage = val;
            this.getUserList();
        },
        getUserList() {
            getUserList({ searchForm: this.searchForm, perPage: this.perPage, currentPage: this.currentPage }).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.list = data.data.data;
                    this.total = data.data.total;
                    this.perPage = data.data.per_page;
                    this.currentPage = data.data.current_page;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        getCheckRole() {
            getCheckRole().then(res => {
                var data = res;
                if (data.code == 1) {
                    this.checkRole = data.data.list;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        delUser(id) {
            var ids = id ? id : this.checkIds.toString();
            this.$confirm('此操作将永久删除数据，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delUser({ ids: ids }).then(res => {
                    var data = res;
                    if (data.code == 1) {
                        this.$message.success(data.msg);

                        if (this.total % this.perPage === 1) {
                            this.currentPage = this.currentPage - 1 > 0 ? this.currentPage - 1 : 1;
                        }

                        this.getUserList();
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        },
        showForm(name, id = 0) {
            if (name == 'add') {
                this.addFormDialog = true;
            } else if (name == 'edit') {
                this.editFormDialog = true;
                this.editFormId = id;
            }
        },
        closeForm(name) {
            if (name == 'add') {
                this.addFormDialog = false;
            } else if (name == 'edit') {
                this.editFormDialog = false;
            }
        }
    }
};
</script>

<style lang="less" scoped>
.header {
    background: #fff;
    margin-bottom: 20px;
}
.content {
    .pagination {
        padding: 10px;
    }
}
</style>
